
import {
  defineComponent,
  reactive,
  toRefs,
  ref,
  getCurrentInstance,
  ComponentInternalInstance,
  onMounted,
  onActivated,
  nextTick
} from 'vue'
import { useRouter, onBeforeRouteLeave } from 'vue-router'
import ZEmpty from '@/components/layout/z-empty.vue'
import ZSwitch from '@/components/layout/z-switch.vue'

interface stateOption {
  search: string
  list: any
  type: string
  pageNum: number
  hasNextPage: boolean
  isRefresh: boolean
  loading: boolean
  loadTimeout: boolean
  scrollFlag: boolean
  scrollTop: number
}

export default defineComponent({
  components: {
    ZEmpty,
    ZSwitch
  },
  props: {
    type: String
  },
  setup(props, { emit }) {
    const { proxy }: { proxy: any } = getCurrentInstance() as ComponentInternalInstance
    const { type } = toRefs(props)
    const router = useRouter()

    const currentRouteName = ref(router.currentRoute.value.name)

    const state = reactive({
      search: '',
      list: [],
      type: type.value,
      pageNum: 1,
      hasNextPage: false,
      isRefresh: false,
      scrollFlag: false,
      scrollTop: 0
    }) as stateOption

    onMounted(() => {
      getList()
    })

    onActivated(() => {
      nextTick(() => {
        document.getElementById('position').scrollTo(0, state.scrollTop)
      })
    })

    const getList = () => {
      if (state.type == 'myLike') {
        proxy.$http.position
          .getCollectPostInfoPageList({ pageNum: state.pageNum, pageSize: 20 })
          .then((res: any) => {
            if (res && res.list) {
              state.hasNextPage = res.hasNextPage
              state.list = [...state.list, ...res.list]
            } else {
              state.hasNextPage = false
              state.list = []
            }
          })
          .finally(() => {
            state.isRefresh = false
            state.scrollFlag = true
          })
      }

      if (state.type == 'myApply') {
        const params = {
          pageNum: state.pageNum,
          pageSize: 10
        }
        proxy.$http.position
          .getMyApplication(params)
          .then((res: any) => {
            if (res && res.list) {
              state.hasNextPage = res.hasNextPage
              state.list = [...state.list, ...res.list]
            } else {
              state.hasNextPage = false
              state.list = []
            }
          })
          .finally(() => {
            state.isRefresh = false
            state.scrollFlag = true
          })
      }
    }

    const handleLoad = () => {
      console.log('load')
      if (state.hasNextPage) {
        state.pageNum++
        getList()
      }
    }

    const handleJobClick = (item: any) => {
      router.push({
        name: 'PositionDetail',
        query: {
          id: item.id || item.postId
        }
      })
    }

    const handlescroll = () => {
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      let windowHeitht = document.documentElement.clientHeight || document.body.clientHeight
      let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight
      if (state.scrollFlag && scrollTop + windowHeitht + 50 >= scrollHeight) {
        state.scrollFlag = false
        load()
      }
    }

    const load = () => {
      if (state.hasNextPage) {
        state.pageNum++
        getList()
      }
    }
    const toPosition = () => {
      router.push({
        name: 'social'
      })
    }

    const onRefresh = () => {
      state.hasNextPage = false
      state.pageNum = 1
      state.list = []
      getList()
    }

    /**
     * 离开前记录scrollTop
     */
    onBeforeRouteLeave((to, from, next) => {
      if (to.name == 'PositionDetail') {
        let scrollTop = document.getElementById('position').scrollTop
        state.scrollTop = scrollTop
      }
      next()
    })

    return {
      ...toRefs(state),
      getList,
      handleJobClick,
      toPosition,
      currentRouteName,
      handlescroll,
      onRefresh,
      handleLoad
    }
  }
})
